import React,{ useState } from 'react'
import "../singleCareer/single.scss"
import { Link } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { active } from '../../redux/dataSlice5';
import { axiosInstance } from '../../config';
import Spinner from '../../components/spinner';

const SingleNote = () => {

  const [updated, setUpdated] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.value);
  const [info, setInfo] = useState({
    title: '',
    type: '',
    course: '',
    class: '',
    subject: '',
    description: '',
    isPaid: '',
    originalPrice: '',
    discountPrice: '',
    pdfUrl: '',
  });

  const handleChange = (e) => {
    const { id, value } = e.target;

    const updatedValue = id === 'isPaid' ? value === 'true' : value;
    setInfo((prev) => ({ ...prev, [id]: updatedValue }));
  };

  const handleUpdate = async (id, e) => {
    e.preventDefault();
    try{
        const updatedNote = { ...info }
        
        Object.keys(updatedNote).forEach((key) => {
          if (updatedNote[key] === null || updatedNote[key] === '') {
            delete updatedNote[key];
          }
        });
        const update = await axiosInstance.put(`/update-note/${id}`, updatedNote)
        setInfo({
          title: '',
          type: '',
          course: '',
          class: '',
          subject: '',
          description: '',
          isPaid: '',
          originalPrice: '',
          discountPrice: '',
          pdfUrl: ''
        });
        dispatch(active(update.data))
        setUpdated(true);
    }catch(err){
        console.log(err);
    }
  }

  return (
    <div>
      {
        !data && <Spinner/>
      }
    {data && 
    <div className='single'>
        <div className='top'>
          <p>Note Details</p>
          <Link to='/notes'> 
            <button className='Btn'>Back</button>
          </Link>
        </div>
        <div className="client">
          <div className="userShow">
            <div className="userShowTop">
              <div className="userShowTopTitle">
                <span className="userShowFullname">{data.title}</span>
              </div>
            </div>
            <div className="userShowBottom">
              <div className="userShowInfo">
                <span>Category: </span>
                <span className="userShowInfoTitle">{data.type}</span>
              </div>
              <div className="userShowInfo">
                <span>Exam: </span>
                <span className="userShowInfoTitle">{data.course}</span>
              </div>
              <div className="userShowInfo">
                <span>Class: </span>
                <span className="userShowInfoTitle">{data.class}</span>
              </div>
              <div className="userShowInfo">
                <span>Subject: </span>
                <span className="userShowInfoTitle">{data.subject}</span>
              </div>
              <div className="userShowInfo">
                <span>Desc: </span>
                <span className="userShowInfoTitle">{data.description}</span>
              </div>
              <div className="userShowInfo">
                <span>Type: </span>
                <span className="userShowInfoTitle">{data.isPaid ? "Paid" : "Unpaid"}</span>
              </div>
              <div className="userShowInfo">
                <span>Original Price: </span>
                <div className="userShowInfoTitle">{data.originalPrice}</div>
              </div>
              <div className="userShowInfo">
                <span>Discounted Price: </span>
                <div className="userShowInfoTitle">{data.discountPrice}</div>
              </div>
              <div className="userShowInfo">
                <span>Document: </span>
                <span className="userShowInfoTitle"><a href={data.pdfUrl} target="_blank" rel="noopener noreferrer">View</a></span>
              </div>
            </div>
          </div>
          <div className="userUpdate">
            <span className="userUpdateTitle">EDIT</span>
            {updated && (
                <p className='updated'>
                  <span>Details Updated Successfully!</span>
                  <span onClick={() => setUpdated(false)} style={{ cursor: 'pointer' }} >X</span>
                </p>
            )}
            <form className="userUpdateForm">
              <div className="userUpdateLeft">
                <div className="userUpdateItem">
                  <label>Title</label>
                  <input
                    type="text"
                    id = "title"
                    onChange={handleChange}
                    placeholder={data.title}
                    value={info.title}
                    className="userUpdateInput"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Category</label>
                  <select name="category" onChange={handleChange} value={info.type} id="type">
                    <option value={data.type}>Select</option>
                    <option value="academics">Academics</option>
                    <option value="government exams">Government Exams</option>
                  </select>
                </div>

                <div className="userUpdateItem">
                  <label>Exam</label> 
                  <select name="course" onChange={handleChange} value={info.course} id="course">
                    <option value={data.course ? data.course : ""}>Select</option>
                    <option value="ssc mts">SSC MTS</option>
                    <option value="ssc chsl">SSC CHSL</option>
                    <option value="ssc cgl">SSC CGL</option>
                    <option value="ssc cpo">SSC CPO</option>
                    <option value="ssc je">SSC JE</option>
                    <option value="ssc steno">SSC Steno</option>
                    <option value="dsssb">DSSSB</option>
                    <option value="nda">NDA</option>
                    <option value="cds">CDS</option>
                    <option value="cuet">CUET</option>
                    <option value="clat">CLAT</option>
                    <option value="police services">Police Services</option>
                    <option value="dp constable">DP Constable</option>
                    <option value="dp head constable">DP Head Constable</option>
                    <option value="dp sub inspector">DP Sub Inspector</option>
                    <option value="up police constable">UP Police Constable</option>
                    <option value="hp constable">HP Constable</option>
                    <option value="rpf constable">RPF Constable</option>
                    <option value="rajasthan police">Rajasthan Police</option>
                    <option value="rrb group d">RRB Group D</option>
                    <option value="railway">Railway</option>
                    <option value="bank po">Bank PO</option>
                    <option value="ibps so">IBPS SO</option>
                    <option value="general english">General English</option>
                    <option value="general hindi">General Hindi</option>
                    <option value="competitive maths">Competitive Maths</option>
                    <option value="logical reasoning">Logical Reasoning</option>
                    <option value="quantitative aptitude">Quantitative Aptitude</option>
                    <option value="general knowledge">General Knowledge</option>
                  </select>
                </div>

                <div className="userUpdateItem">
                  <label>Class</label>
                  <select name="class" onChange={handleChange} value={info.class} id="class">
                    <option value={data.class ? data.class : "" }>Select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
                </div>

                <div className="userUpdateItem">
                <label>Subject</label>
                <select name="subject" onChange={handleChange} value={info.subject} id="subject">
                  <option value="">Select</option>
                  <option value="mathematics">Mathematics</option>
                  <option value="english">English</option>
                  <option value="hindi">Hindi</option>
                  <option value="environmental studies">Environmental Studies</option>
                  <option value="science">Science</option>
                  <option value="social science">Social Science</option>
                  <option value="sanskrit">Sanskrit</option>
                  <option value="mathematics standards">Mathematics Standards</option>
                  <option value="mathematics basics">Mathematics Basics</option>
                  <option value="history">History</option>
                  <option value="computer science">Computer Science</option>
                  <option value="geography">Geography</option>
                  <option value="political science">Political Science</option>
                  <option value="economics">Economics</option>
                  <option value="vocational">Vocational</option>
                  <option value="hindi core">Hindi Core</option>
                  <option value="hindi elective">Hindi Elective</option>
                  <option value="english core">English Core</option>
                  <option value="english elective">English Elective</option>
                  <option value="data analytics">Data Analytics</option>
                  <option value="information technology">Information Technology</option>
                  <option value="chemistry">Chemistry</option>
                  <option value="business studies">Business Studies</option>
                  <option value="web designing">Web Designing</option>
                  <option value="biology">Biology</option>
                  <option value="accountancy">Accountancy</option>
                  <option value="retail">Retail</option>
                  <option value="physics">Physics</option>
                  <option value="beauty parlour">Beauty Parlour</option>
                  <option value="home science">Home Science</option>
                  <option value="health and wellness">Health and Wellness</option>
                  <option value="entrepreneurship">Entrepreneurship</option>
                  <option value="finearts">Finearts</option>
                  <option value="psychology">Psychology</option>
                  <option value="sociology">Sociology</option>
                  <option value="physical education">Physical Education</option>
                  <option value="information practices">Information Practices</option>
                  <option value="engineering graphics">Engineering Graphics</option>
                  <option value="legal studies">Legal Studies</option>
                  <option value="fine arts">Fine Arts</option>
                </select>
                </div>
                <div className="userUpdateItem">
                  <label>Desc:</label>
                  <input
                    type="text"
                    id = "description"
                    onChange={handleChange}
                    value={info.description}
                    placeholder={data.description}
                    className="userUpdateInput"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Type</label>
                  <select name="isPaid" onChange={handleChange} value={info.isPaid} id="isPaid">
                    <option value={data.isPaid}>Select</option>
                    <option value="true">Paid</option>
                    <option value="false">Unpaid</option>
                  </select>
                </div>
                <div className="userUpdateItem">
                  <label>Original Price</label>
                  <input
                    type="text"
                    id = "originalPrice"
                    onChange={handleChange}
                    value={info.originalPrice}
                    placeholder={data.originalPrice}
                    className="userUpdateInput"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Discount Price</label>
                  <input
                    type="text"
                    id = "discountPrice"
                    onChange={handleChange}
                    value={info.discountPrice}
                    placeholder={data.discountPrice}
                    className="userUpdateInput"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>PDF URL</label>
                  <input
                    type="text"
                    id = "pdfUrl"
                    onChange={handleChange}
                    value={info.pdfUrl}
                    placeholder={data.pdfUrl}
                    className="userUpdateInput"
                  />
                </div>
                <button className="userUpdateButton" onClick={(e) => handleUpdate(data._id, e)}>Update</button>
              </div>
            </form>
          </div>
        </div>
    </div>
    }
    </div>
  )
}

export default SingleNote
import React, { useContext } from 'react';
import "./sidebar.scss";
import { NavLink, Link } from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import RateReviewIcon from '@mui/icons-material/RateReview';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { AuthContext } from '../../authContext';


const SideNav = ({ setDark }) => {
  const { user } = useContext(AuthContext);
  const isSuperAdmin = user?.isSuperAdmin;

  return (
    <div className='sidenav'>
        <div className='top'>
            <Link to='/'>
                <img src="/logo.png" alt="TRG Logo" />
            </Link>
        </div>
        <hr />
        <div className='center'>
            <ul>
                <p className='title'>MAIN</p>
                <NavLink to='/' activeclassname='active'>
                    <li><DashboardIcon className='icon' /> <span>Dashboard</span></li>
                </NavLink>
                {isSuperAdmin && (
                  <>
                    <p className='title'>TRG MATERIALS</p>
                    <NavLink to='notes' activeclassname='active'>
                        <li><LibraryBooksIcon className='icon' /> <span>Notes</span></li>
                    </NavLink>
                    <NavLink to='materials' activeclassname='active'>
                        <li><LibraryBooksIcon className='icon' /> <span>Materials</span></li>
                    </NavLink>
                  </>
                )}
                <p className='title'>LIST</p>
                <NavLink to='leads' activeclassname='active'>
                    <li><GroupIcon className='icon' /> <span>Lead Forms</span></li>
                </NavLink>
                <NavLink to='careers' activeclassname='active'>
                    <li><GroupIcon className='icon' /> <span>Career Forms</span></li>
                </NavLink>
                <NavLink to='internships' activeclassname='active'>
                    <li><GroupIcon className='icon' /> <span>Internship Forms</span></li>
                </NavLink>
                <NavLink to='franchises' activeclassname='active'>
                    <li><GroupIcon className='icon' /> <span>Franchise Forms</span></li>
                </NavLink>
                {isSuperAdmin && (
                  <>
                    <p className='title'>DYNAMIC CONTENT</p>
                    <NavLink to='carousel-entries' activeclassname='active'>
                        <li><NoteAddIcon className='icon' /> <span>Carousel Entries</span></li>
                    </NavLink>
                    <NavLink to='announcement-entries' activeclassname='active'>
                        <li><NoteAddIcon className='icon' /> <span>Announcements</span></li>
                    </NavLink>
                    <NavLink to='student-entries' activeclassname='active'>
                        <li><NoteAddIcon className='icon' /> <span>Student Entries</span></li>
                    </NavLink>
                    <NavLink to='teacher-entries' activeclassname='active'>
                        <li><NoteAddIcon className='icon' /> <span>Teacher Entries</span></li>
                    </NavLink>
                  </>
                )}
                <p className='title'>REVIEWS</p>
                <NavLink to='reviews' activeclassname='active'>
                    <li><RateReviewIcon className='icon' /> <span>Review Entries</span></li>
                </NavLink>
            </ul>
        </div>
        <div className='bottom'>
            <div className="colorOptions" onClick={() => setDark(false)}></div>
            <div className="colorOptions" onClick={() => setDark(true)}></div>
        </div>
    </div>
  );
}

export default SideNav;

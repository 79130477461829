import Home from "./pages/home";
import Login from "./pages/login";
import CareerList from "./pages/careerList";
import SingleCareer from "./pages/singleCareer";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SideNav from "./components/sidebar";
import Navbar from "./components/navbar";
import "./app.scss";
import { useContext, useState } from "react";
import { AuthContext } from "./authContext";
import InternList from "./pages/internshipList";
import FranchiseList from "./pages/franchiseList";
import SingleIntern from "./pages/singleIntern";
import SingleFranchise from "./pages/singleFranchise";
import CarouselEntries from "./pages/carouselEntries";
import AnnouncementEntries from "./pages/announcementEntries";
import StudentEntries from "./pages/studentEntires";
import TeacherEntries from "./pages/teacherEntires";
import LeadList from "./pages/leadList";
import SingleLead from "./pages/singleLead";
import ReviewList from "./pages/ReviewList";
import SingleReview from "./pages/singleReview";
import NoteList from "./pages/noteList";
import SingleNote from "./pages/singleNote";
import StudyMaterialList from "./pages/materialList";
import SingleMaterial from "./pages/singleMaterial";
import CreateMaterial from "./pages/createMaterial";
import CreateNote from "./pages/createNote";

function App() {
  const [dark, setDark] = useState(false);
  const [nav, setNav] = useState(false);

  const { user } = useContext(AuthContext);
  const isSuperAdmin = user?.isSuperAdmin;

  const ProtectedRoute = ({ children }) => {
    if (!user) {
      setNav(false);
      return <Navigate to="/login" />;
    }

    setNav(true);
    return children;
  };

  return (
    <div className={dark ? 'App dark' : 'App'}>
      <BrowserRouter>
        {nav && <SideNav setDark={setDark} />}
        <div className="container">
          {nav && <Navbar setDark={setDark} />}
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            } />
            <Route path="careers">
              <Route index element={
                <ProtectedRoute>
                  <CareerList />
                </ProtectedRoute>
              } />
              <Route path=":careerId" element={
                <ProtectedRoute>
                  <SingleCareer />
                </ProtectedRoute>
              } />
            </Route>
            <Route path="internships">
              <Route index element={
                <ProtectedRoute>
                  <InternList />
                </ProtectedRoute>
              } />
              <Route path=":internshipId" element={
                <ProtectedRoute>
                  <SingleIntern />
                </ProtectedRoute>
              } />
            </Route>
            <Route path="franchises">
              <Route index element={
                <ProtectedRoute>
                  <FranchiseList />
                </ProtectedRoute>
              } />
              <Route path=":franchiseId" element={
                <ProtectedRoute>
                  <SingleFranchise />
                </ProtectedRoute>
              } />
            </Route>
            <Route path="leads">
              <Route index element={
                <ProtectedRoute>
                  <LeadList />
                </ProtectedRoute>
              } />
              <Route path=":leadId" element={
                <ProtectedRoute>
                  <SingleLead />
                </ProtectedRoute>
              } />
            </Route>
            <Route path="reviews">
              <Route index element={
                <ProtectedRoute>
                  <ReviewList />
                </ProtectedRoute>
              } />
              <Route path=":reviewId" element={
                <ProtectedRoute>
                  <SingleReview />
                </ProtectedRoute>
              } />
            </Route>
            {isSuperAdmin && (
              <>
                <Route path="notes">
                  <Route index element={
                    <ProtectedRoute>
                      <NoteList />
                    </ProtectedRoute>
                  } />
                  <Route path=":noteId" element={
                    <ProtectedRoute>
                      <SingleNote />
                    </ProtectedRoute>
                  } />
                  <Route path="create-note" element={
                    <ProtectedRoute>
                      <CreateNote />
                    </ProtectedRoute>
                  } />
                </Route>
                <Route path="materials">
                  <Route index element={
                    <ProtectedRoute>
                      <StudyMaterialList />
                    </ProtectedRoute>
                  } />
                  <Route path=":materialId" element={
                    <ProtectedRoute>
                      <SingleMaterial />
                    </ProtectedRoute>
                  } />
                  <Route path="create-material" element={
                    <ProtectedRoute>
                      <CreateMaterial />
                    </ProtectedRoute>
                  } />
                </Route>
                <Route path="carousel-entries">
                  <Route index element={
                    <ProtectedRoute>
                      <CarouselEntries />
                    </ProtectedRoute>
                  } />
                </Route>
                <Route path="announcement-entries">
                  <Route index element={
                    <ProtectedRoute>
                      <AnnouncementEntries />
                    </ProtectedRoute>
                  } />
                </Route>
                <Route path="student-entries">
                  <Route index element={
                    <ProtectedRoute>
                      <StudentEntries />
                    </ProtectedRoute>
                  } />
                </Route>
                <Route path="teacher-entries">
                  <Route index element={
                    <ProtectedRoute>
                      <TeacherEntries />
                    </ProtectedRoute>
                  } />
                </Route>
              </>
            )}
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
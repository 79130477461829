import React, { useState } from 'react';
import { axiosInstance } from '../../config';
import { Link } from 'react-router-dom';
import '../../pages/createNote/create-note.scss';

const CreateNote = () => {
  const [info, setInfo] = useState({
    title: '',
    type: '',
    course: '',
    class: '',
    subject: '',
    description: '',
    isPaid: '',
    originalPrice: '',
    discountPrice: '',
    pdfUrl: '',
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;

    const updatedValue = id === 'isPaid' ? value === 'true' : value;
    setInfo((prev) => ({ ...prev, [id]: updatedValue }));
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      const newNote = { ...info };

      // Iterate through the newNote object and delete empty values
      Object.keys(newNote).forEach((key) => {
        if (!newNote[key]) {
          delete newNote[key];
        }
      });

      await axiosInstance.post('/create-note/', newNote);
      alert("Note Created Successfully!");

      // Reset the form fields
      setInfo({
        title: '',
        type: '',
        course: '',
        class: '',
        subject: '',
        description: '',
        isPaid: '',
        originalPrice: '',
        discountPrice: '',
        pdfUrl: '',
      });
    } catch (err) {
      console.log(err);
      alert("An error occurred while creating the note.");
    }
  };

  return (
    <div>
      <div className='single'>
        <div className='form-top'>
          <p>Create New Note</p>
          <Link to='/notes'>
            <button className='Btn'>Back</button>
          </Link>
        </div>
        <div className="client">
          <div className="noteUpdate">
            <form className="noteUpdateForm">
              <div className="noteUpdateItem">
                <label>Title</label>
                <input
                  type="text"
                  id="title"
                  value={info.title}
                  onChange={handleChange}
                  placeholder="Enter title"
                  className="noteUpdateInput"
                  required
                />
              </div>
              <div className="noteUpdateItem">
                <label>Category</label>
                <select
                  name="type"
                  id="type"
                  value={info.type}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select</option>
                  <option value="academics">Academics</option>
                  <option value="government exams">Government Exams</option>
                </select>
              </div>
              <div className="noteUpdateItem">
                <label>Exam</label>
                <select
                  name="course"
                  id="course"
                  value={info.course}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="ssc mts">SSC MTS</option>
                  <option value="ssc chsl">SSC CHSL</option>
                  <option value="ssc cgl">SSC CGL</option>
                  <option value="ssc cpo">SSC CPO</option>
                  <option value="ssc je">SSC JE</option>
                  <option value="ssc steno">SSC Steno</option>
                  <option value="dsssb">DSSSB</option>
                  <option value="nda">NDA</option>
                  <option value="cds">CDS</option>
                  <option value="cuet">CUET</option>
                  <option value="clat">CLAT</option>
                  <option value="police services">Police Services</option>
                  <option value="dp constable">DP Constable</option>
                  <option value="dp head constable">DP Head Constable</option>
                  <option value="dp sub inspector">DP Sub Inspector</option>
                  <option value="up police constable">UP Police Constable</option>
                  <option value="hp constable">HP Constable</option>
                  <option value="rpf constable">RPF Constable</option>
                  <option value="rajasthan police">Rajasthan Police</option>
                  <option value="rrb group d">RRB Group D</option>
                  <option value="railway">Railway</option>
                  <option value="bank po">Bank PO</option>
                  <option value="ibps so">IBPS SO</option>
                  <option value="general english">General English</option>
                  <option value="general hindi">General Hindi</option>
                  <option value="competitive maths">Competitive Maths</option>
                  <option value="logical reasoning">Logical Reasoning</option>
                  <option value="quantitative aptitude">Quantitative Aptitude</option>
                  <option value="general knowledge">General Knowledge</option>
                </select>
              </div>
              <div className="noteUpdateItem">
                <label>Class</label>
                <select
                  name="class"
                  id="class"
                  value={info.class}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {Array.from({ length: 12 }, (_, i) => i + 1).map(num => (
                    <option key={num} value={num}>{num}</option>
                  ))}
                </select>
              </div>
              <div className="noteUpdateItem">
                <label>Subject</label>
                <select name="subject" onChange={handleChange} value={info.subject} id="subject">
                  <option value="">Select</option>
                  <option value="mathematics">Mathematics</option>
                  <option value="english">English</option>
                  <option value="hindi">Hindi</option>
                  <option value="environmental studies">Environmental Studies</option>
                  <option value="science">Science</option>
                  <option value="social science">Social Science</option>
                  <option value="sanskrit">Sanskrit</option>
                  <option value="mathematics standards">Mathematics Standards</option>
                  <option value="mathematics basics">Mathematics Basics</option>
                  <option value="history">History</option>
                  <option value="computer science">Computer Science</option>
                  <option value="geography">Geography</option>
                  <option value="political science">Political Science</option>
                  <option value="economics">Economics</option>
                  <option value="vocational">Vocational</option>
                  <option value="hindi core">Hindi Core</option>
                  <option value="hindi elective">Hindi Elective</option>
                  <option value="english core">English Core</option>
                  <option value="english elective">English Elective</option>
                  <option value="data analytics">Data Analytics</option>
                  <option value="information technology">Information Technology</option>
                  <option value="chemistry">Chemistry</option>
                  <option value="business studies">Business Studies</option>
                  <option value="web designing">Web Designing</option>
                  <option value="biology">Biology</option>
                  <option value="accountancy">Accountancy</option>
                  <option value="retail">Retail</option>
                  <option value="physics">Physics</option>
                  <option value="beauty parlour">Beauty Parlour</option>
                  <option value="home science">Home Science</option>
                  <option value="health and wellness">Health and Wellness</option>
                  <option value="entrepreneurship">Entrepreneurship</option>
                  <option value="finearts">Finearts</option>
                  <option value="psychology">Psychology</option>
                  <option value="sociology">Sociology</option>
                  <option value="physical education">Physical Education</option>
                  <option value="information practices">Information Practices</option>
                  <option value="engineering graphics">Engineering Graphics</option>
                  <option value="legal studies">Legal Studies</option>
                  <option value="fine arts">Fine Arts</option>
                </select>
              </div>
              <div className="noteUpdateItem">
                <label>Description</label>
                <input
                  type="text"
                  id="description"
                  value={info.description}
                  onChange={handleChange}
                  placeholder="Enter description"
                  className="noteUpdateInput"
                />
              </div>
              <div className="noteUpdateItem">
                <label>Type</label>
                <select
                  name="isPaid"
                  id="isPaid"
                  value={info.isPaid}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select</option>
                  <option value="true">Paid</option>
                  <option value="false">Unpaid</option>
                </select>
              </div>
              <div className="noteUpdateItem">
                <label>Original Price</label>
                <input
                  type="text"
                  id="originalPrice"
                  value={info.originalPrice}
                  onChange={handleChange}
                  placeholder="Enter original price"
                  className="noteUpdateInput"
                />
              </div>
              <div className="noteUpdateItem">
                <label>Discount Price</label>
                <input
                  type="text"
                  id="discountPrice"
                  value={info.discountPrice}
                  onChange={handleChange}
                  placeholder="Enter discount price"
                  className="noteUpdateInput"
                />
              </div>
              <div className="noteUpdateItem">
                <label>PDF URL</label>
                <input
                  type="text"
                  id="pdfUrl"
                  value={info.pdfUrl}
                  onChange={handleChange}
                  placeholder="Enter PDF URL"
                  className="noteUpdateInput"
                />
              </div>
              <button className="noteUpdateButton" onClick={handleCreate}>Create Note</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNote;
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: null,
}

const dataSlice6 = createSlice({
    name: 'data',
    initialState: initialState,
    reducers: {
        active: (state, action) => {
            state.value = action.payload;
        },
        notActive: (state) => {
            state.value = null;
        }
    },
})

export const { active, notActive } = dataSlice6.actions;

export default dataSlice6.reducer;

import React from 'react'
import "./home.scss"
// import Widget from '../../components/widget'
// import Chart from '../../components/chart'

const Home = () => {
  return (
    <div className='homeWrapper'>
      <div className='homeContainer'>
        <img src="/images/bg_1.png" alt="Admin Background" />
      </div>
      <h3 className='bottom-heading'>The Right Guru Admin</h3>
    </div>
  )
}

export default Home
import React,{useContext} from 'react'
import "./navbar.scss"
import { AuthContext } from "../../authContext";

import SearchIcon from '@mui/icons-material/Search';
import LogoutIcon from '@mui/icons-material/Logout';

const Navbar = () => {
  const { dispatch } = useContext(AuthContext);
  return (
    <div className='navbar'>
      <div className='wrapper'>
        <div className='search'>
          <input type="text" placeholder='Search...' />
          <SearchIcon className='icon'/>
        </div>
        <div className='items'>
          <div className='item' onClick={()=> dispatch({type: "LOGOUT"})}>
            <LogoutIcon className='i'/>
            Logout
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
import React, { useState, useContext, useEffect } from 'react';
import "../../components/dataTable/datatable.scss";
import { Link, NavLink } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import useFetch from '../../hooks/useFetch';
import { axiosInstance } from '../../config';
import { useDispatch } from 'react-redux';
import { active } from '../../redux/dataSlice6';
import { AuthContext } from "../../authContext";
import Spinner from '../../components/spinner';

// Define filter options based on your schema
const categories = ["support material", "syllabus", "assignment", "ncert book", "ncert solutions", "previous year question paper"];
const classes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const subjects = [
    "mathematics", "english", "hindi", "environmental studies", "science", 
    "social science", "sanskrit", "mathematics standards", "mathematics basics",
    "history", "computer science", "geography", "political science", "economics",
    "vocational", "hindi core", "hindi elective", "english core", "english elective",
    "data analytics", "information technology", "chemistry", "business studies",
    "web designing", "biology", "accountancy", "retail", "physics", "beauty parlour",
    "home science", "health and wellness", "entrepreneurship", "finearts",
    "psychology", "sociology", "physical education", "information practices",
    "engineering graphics", "legal studies", "fine arts"
  ];
const exams = ['cbse', 'icse'];

const columns = [
    { field: 'title', headerName: 'Title', width: 200 },
    { field: 'type', headerName: 'Category', width: 170 },
    { field: 'course', headerName: 'Board', width: 160 },
    { field: 'class', headerName: 'Class', width: 100 },
    { field: 'subject', headerName: 'Subject', width: 150 },
    {
        field: 'isPaid',
        headerName: 'Type',
        width: 120,
        renderCell: (params) => {
            return (<div className={`cellWithStatus ${params.row.isPaid ? 'in-process' : 'new'}`}>{params.row.isPaid ? "Paid" : "Unpaid"}</div>);
        }
    },
];

const StudyMaterialList = () => {
    const { user } = useContext(AuthContext);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredList, setFilteredList] = useState([]);
    const { data } = useFetch(`/fetch-materials/${user._id}`);
    const dispatch = useDispatch();

    const [filters, setFilters] = useState({
        category: '',
        class: '',
        subject: '',
        course: ''
    });

    useEffect(() => {
        setList(data);
        setFilteredList(data);
        setLoading(false);
    }, [data]);

    useEffect(() => {
        setLoading(true);
        let filtered = list;

        if (filters.category) {
            filtered = filtered.filter(item => item.type === filters.category);
        }

        if (filters.class) {
            filtered = filtered.filter(item => item.class === Number(filters.class));
        }

        if (filters.subject) {
            filtered = filtered.filter(item => item.subject === filters.subject);
        }

        if (filters.course) {
            filtered = filtered.filter(item => item.course === filters.course);
        }

        setFilteredList(filtered);
        setLoading(false);
    }, [filters, list]);

    const handleDelete = async (id) => {
        try {
            await axiosInstance.delete(`/delete-material/${id}`);
            setList(list.filter((item) => item._id !== id));
        } catch (err) {
            console.log(err);
        }
    };

    const handleView = async (id) => {
        try {
            const single = await axiosInstance.get(`/fetch-material/${id}`);
            dispatch(active(single.data));
        } catch (err) {
            console.log(err);
        }
    };

    const formCreateButtonStyle = {
        padding: '8px 24px',
        backgroundColor: 'cornflowerblue',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        fontWeight: '500',
        fontSize: '15px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'all 200ms ease-in',
    };

    const formCreateButtonHoverStyle = {
        backgroundColor: 'rgba(53, 53, 255, 0.85)',
    };

    const filterSectionStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '25px',
    };

    const selectStyle = {
        width: '200px',
        padding: '10px',
        fontSize: '16px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        outline: 'none',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
    };

    const optionStyle = {
        padding: '10px',
        backgroundColor: '#fff',
        color: '#333',
    };

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className='cellAction'>
                        <Link to={`${params.row._id}`} style={{ textDecoration: 'none' }}>
                            <div className='viewBtn' onClick={async () => await handleView(params.row._id)}>VIEW</div>
                        </Link>
                        <div className='deleteBtn' onClick={() => handleDelete(params.row._id)}>DELETE</div>
                    </div>
                )
            }
        }
    ];

    const handleFilterChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };

    return (
        <div className='studyMaterialList'>
            <div className='filterSection' style={filterSectionStyle}>
                <div style={{ display: 'flex', gap: '16px' }}>
                    <select name="course" onChange={handleFilterChange} style={selectStyle}>
                        <option value="">All Boards</option>
                        {exams.map((exam) => (
                            <option style={optionStyle} value={exam} key={exam}>{exam}</option>
                        ))}
                    </select>
                    <select name="category" onChange={handleFilterChange} style={selectStyle}>
                        <option value="">All Categories</option>
                        {categories.map((cat) => (
                            <option style={optionStyle} value={cat} key={cat}>{cat}</option>
                        ))}
                    </select>
                    <select name="class" onChange={handleFilterChange} style={selectStyle}>
                        <option value="">All Classes</option>
                        {classes.map((cls) => (
                            <option style={optionStyle} value={cls} key={cls}>Class {cls}</option>
                        ))}
                    </select>
                    <select name="subject" onChange={handleFilterChange} style={selectStyle}>
                        <option value="">All Subjects</option>
                        {subjects.map((sub) => (
                            <option style={optionStyle} value={sub} key={sub}>{sub}</option>
                        ))}
                    </select>
                </div>
                <NavLink to='create-material' style={formCreateButtonStyle} 
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = formCreateButtonHoverStyle.backgroundColor}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = formCreateButtonStyle.backgroundColor} 
                >
                    Create New Material
                </NavLink>
            </div>
            <div className='data-table'>
                { loading && <Spinner />}
                { !loading && <DataGrid
                    className='dataGrid'
                    rows={filteredList}
                    columns={columns.concat(actionColumn)}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    getRowId={row => row._id}
                />}
            </div>
        </div>
    )
}

export default StudyMaterialList;
import React, { useState } from 'react'
import "../../components/dataTable/datatable.scss"
import { Link } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import useFetch from '../../hooks/useFetch'
import { useEffect } from 'react';
import { axiosInstance } from '../../config';
import { useDispatch } from 'react-redux';
import { active } from '../../redux/dataSlice3';
import Spinner from '../../components/spinner';

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        width: 200,
    },
    { field: 'phone', headerName: 'Mobile No', width: 180 },
    { field: 'exam', headerName: 'Exam', width: 180 },
    { field: 'createdAt', headerName: 'Date', type: 'date', width: 150 },
    {
        field: 'status',
        headerName: 'Status',
        width: 150,
        renderCell: (params) =>{
            return (<div className={`cellWithStatus ${params.row.status}`}>{params.row.status}</div>);
        }
    },
];

const LeadList = () => {

    const [list, setList] = useState();
    const [loading, setLoading] = useState(true);
    const { data } = useFetch("/lead-form/")
    const dispatch = useDispatch();
    
    useEffect(() => {
        setList(data);
        setLoading(false);
    },[data])

    const handleDelete = async (id) => {
        try{
            await axiosInstance.delete(`/lead-form/${id}`)
            setList(list.filter((item) => item._id !== id))
        }catch(err){
            console.log(err);
        }
    }

    const handleView = async (id) => {
        try{
            const single = await axiosInstance.get(`/lead-form/${id}`)
            dispatch(active(single.data));
        }catch(err){
            console.log(err);
        }
    }


    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params)=>{
                return(
                    <div className='cellAction'>
                        <Link to={`${params.row._id}`} style={{textDecoration:'none'}}>
                            {/* <div className='viewBtn'>VIEW</div> */}
                            <div className='viewBtn' onClick={async() => await handleView(params.row._id)}>VIEW</div>
                        </Link>
                        <div className='deleteBtn' onClick={() => handleDelete(params.row._id)}>DELETE</div>
                    </div>
                )
            }
        }
    ]
  return (
    <div className='data-table'>
        {
            loading && <Spinner/>
        }
        
        { !loading && list && <DataGrid
            className='dataGrid'
            rows = {list}
            columns={columns.concat(actionColumn)}
            pageSize={10}
            rowsPerPageOptions={[10]}
            getRowId={row => row._id}
        />}
    </div>
  )
}

export default LeadList
import React, { useState } from 'react'
import "../../components/dataTable/datatable.scss"
import { DataGrid } from '@mui/x-data-grid';
import useFetch from '../../hooks/useFetch'
import { useEffect } from 'react';
import { axiosInstance } from '../../config';
import Spinner from '../../components/spinner';
import "./carousel.scss"

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        width: 200,
    },
    {
        field: 'imgUrl',
        headerName: 'Image Link',
        width: 400,
        renderCell: (params) => (
            <a href={params.value} target="_blank" rel="noopener noreferrer">
                {params.value}
            </a>
        ),
    },
    {
        field: 'redirectLink',
        headerName: 'Redirect Link',
        width: 400,
    },
];

const CarouselEntries = () => {

    const [list, setList] = useState();
    const [loading, setLoading] = useState();
    const { data } = useFetch("/carousel-entry/");
    const [info, setInfo] = useState({
        name: '',
        imgUrl: '',
        redirectLink: ''
    });

    
    useEffect(() => {
        setList(data);
    },[data])

    const handleDelete = async (id) => {
        try{
            setLoading(true);
            await axiosInstance.delete(`/carousel-entry/${id}`)
            setList(list.filter((item) => item._id !== id))
            setLoading(false);
        }catch(err){
            console.log(err);
        }
    }

    const handleChange = (e) => {
        setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };
    
    const handleCreate = async () => {
        try{
            await axiosInstance.post(`/carousel-entry/`, info);
            setInfo({});
            alert("Carousel entry created!");
        }catch(err){
            console.log(err);
        }
    }

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 150,
            renderCell: (params)=>{
                return(
                    <div className='cellAction'>
                        <div className='deleteBtn' onClick={() => handleDelete(params.row._id)}>DELETE</div>
                    </div>
                )
            }
        }
    ]
  return (
    <div className='carousel-wrapper'>  
        <div className='create-box'>
            <form className="createForm">
                <h3>Create a New Carousel Entry</h3>
                <div className="formItem">
                  <label>Name <span>(required)</span></label>
                  <input
                    type="textarea"
                    id = "name"
                    onChange={handleChange}
                    placeholder="Image-1"
                    className="formInput"
                    required
                  />
                </div>
                <div className="formItem">
                  <label>Image Link <span>(required)</span></label>
                  <input
                    type="textarea"
                    id = "imgUrl"
                    onChange={handleChange}
                    placeholder="https://example.com/image"
                    className="formInput"
                    required
                  />
                </div>
                <div className="formItem">
                  <label>Rediect URL <span>(optional)</span></label>
                  <input
                    type="textarea"
                    id = "redirectLink"
                    onChange={handleChange}
                    placeholder="https://rightguru.com/something"
                    className="formInput"
                  />
                </div>
                <button className="formCreateButton" onClick={() => handleCreate()}>Create</button>
            </form>
            <div className='instructions'>
                <p><b>Instructions - </b></p>
                <p>1. Double check the URLs before adding.</p>
                <p>2. Only use image URLs that are publicly available. You should be able to view the image by simply pasting the URL into a web browser like Chrome.</p>
                <p>3. Please leave Redirect URL empty if you want it to do nothing.</p>
            </div>
        </div>
        <div className='data-table'>
            {
                loading && <Spinner/>
            }
            
            { !loading && list && <DataGrid
                className='dataGrid'
                rows = {list}
                columns={columns.concat(actionColumn)}
                pageSize={8}
                rowsPerPageOptions={[8]}
                getRowId={row => row._id}
            />}
        </div>
    </div>
  )
}

export default CarouselEntries
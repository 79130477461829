import React, { useState } from 'react';
import { axiosInstance } from '../../config';
import { Link } from 'react-router-dom';
import '../../pages/createMaterial/create-material.scss';

const CreateMaterial = () => {
  const [info, setInfo] = useState({
    title: '',
    type: '',
    course: '',
    class: '',
    subject: '',
    description: '',
    isPaid: '',
    originalPrice: '',
    discountPrice: '',
    pdfUrl: '',
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;

    const updatedValue = id === 'isPaid' ? value === 'true' : value;
    setInfo((prev) => ({ ...prev, [id]: updatedValue }));
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      const newMaterial = { ...info };
      Object.keys(newMaterial).forEach((key) => {
        if (!newMaterial[key]) {
          delete newMaterial[key];
        }
      });
      await axiosInstance.post('/create-material/', newMaterial);
      alert("Material Created Successfully!");
      setInfo({
        title: '',
        type: '',
        course: '',
        class: '',
        subject: '',
        description: '',
        isPaid: '',
        originalPrice: '',
        discountPrice: '',
        pdfUrl: '',
      });
    } catch (err) {
      console.log(err);
      alert("An error occurred while creating the material.");
    }
  };

  return (
    <div>
      <div className='single'>
        <div className='form-top'>
          <p>Create New Material</p>
          <Link to='/materials'>
            <button className='Btn'>Back</button>
          </Link>
        </div>
        <div className="client">
          <div className="materialUpdate">
            <form className="materialUpdateForm">
                <div className="materialUpdateItem">
                  <label>Title</label>
                  <input
                    type="text"
                    id="title"
                    onChange={handleChange}
                    value={info.title}
                    placeholder="Enter title"
                    className="materialUpdateInput"
                    required
                  />
                </div>
                <div className="materialUpdateItem">
                  <label>Category</label>
                  <select 
                    name="type" 
                    onChange={handleChange} 
                    id="type" 
                    value={info.type}
                  >
                    <option value="">Select</option>
                    <option value="support material">Support Material</option>
                    <option value="syllabus">Syllabus</option>
                    <option value="assignment">Assignment</option>
                    <option value="ncert book">NCERT Book</option>
                    <option value="ncert solutions">NCERT Solutions</option>
                    <option value="previous year question paper">Previous Year Question Paper</option>
                  </select>
                </div>
                <div className="materialUpdateItem">
                  <label>Board</label>
                  <select 
                    name="course" 
                    onChange={handleChange} 
                    id="course" 
                    value={info.course}
                  >
                    <option value="">Select</option>
                    <option value="cbse">CBSE</option>
                    <option value="icse">ICSE</option>
                  </select>
                </div>
                <div className="materialUpdateItem">
                  <label>Class</label>
                  <select 
                    name="class" 
                    onChange={handleChange} 
                    id="class" 
                    value={info.class}
                  >
                    <option value="">Select</option>
                    {Array.from({ length: 12 }, (_, i) => i + 1).map(num => (
                      <option key={num} value={num}>{num}</option>
                    ))}
                  </select>
                </div>
                <div className="materialUpdateItem">
                  <label>Subject</label>
                  <select name="subject" onChange={handleChange} value={info.subject} id="subject">
                    <option value="">Select</option>
                    <option value="mathematics">Mathematics</option>
                    <option value="english">English</option>
                    <option value="hindi">Hindi</option>
                    <option value="environmental studies">Environmental Studies</option>
                    <option value="science">Science</option>
                    <option value="social science">Social Science</option>
                    <option value="sanskrit">Sanskrit</option>
                    <option value="mathematics standards">Mathematics Standards</option>
                    <option value="mathematics basics">Mathematics Basics</option>
                    <option value="history">History</option>
                    <option value="computer science">Computer Science</option>
                    <option value="geography">Geography</option>
                    <option value="political science">Political Science</option>
                    <option value="economics">Economics</option>
                    <option value="vocational">Vocational</option>
                    <option value="hindi core">Hindi Core</option>
                    <option value="hindi elective">Hindi Elective</option>
                    <option value="english core">English Core</option>
                    <option value="english elective">English Elective</option>
                    <option value="data analytics">Data Analytics</option>
                    <option value="information technology">Information Technology</option>
                    <option value="chemistry">Chemistry</option>
                    <option value="business studies">Business Studies</option>
                    <option value="web designing">Web Designing</option>
                    <option value="biology">Biology</option>
                    <option value="accountancy">Accountancy</option>
                    <option value="retail">Retail</option>
                    <option value="physics">Physics</option>
                    <option value="beauty parlour">Beauty Parlour</option>
                    <option value="home science">Home Science</option>
                    <option value="health and wellness">Health and Wellness</option>
                    <option value="entrepreneurship">Entrepreneurship</option>
                    <option value="finearts">Finearts</option>
                    <option value="psychology">Psychology</option>
                    <option value="sociology">Sociology</option>
                    <option value="physical education">Physical Education</option>
                    <option value="information practices">Information Practices</option>
                    <option value="engineering graphics">Engineering Graphics</option>
                    <option value="legal studies">Legal Studies</option>
                    <option value="fine arts">Fine Arts</option>
                  </select>
                </div>
                <div className="materialUpdateItem">
                  <label>Description</label>
                  <input
                    type="text"
                    id="description"
                    onChange={handleChange}
                    value={info.description}
                    placeholder="Enter description"
                    className="materialUpdateInput"
                  />
                </div>
                <div className="materialUpdateItem">
                  <label>Type</label>
                  <select 
                    name="isPaid" 
                    onChange={handleChange} 
                    id="isPaid" 
                    value={info.isPaid}
                    required
                  >
                    <option value="">Select</option>
                    <option value="true">Paid</option>
                    <option value="false">Unpaid</option>
                  </select>
                </div>
                <div className="noteUpdateItem">
                  <label>Original Price</label>
                  <input
                    type="text"
                    id="originalPrice"
                    value={info.originalPrice}
                    onChange={handleChange}
                    placeholder="Enter original price"
                    className="noteUpdateInput"
                  />
                </div>
                <div className="noteUpdateItem">
                  <label>Discount Price</label>
                  <input
                    type="text"
                    id="discountPrice"
                    value={info.discountPrice}
                    onChange={handleChange}
                    placeholder="Enter discount price"
                    className="noteUpdateInput"
                  />
                </div>
                <div className="materialUpdateItem">
                  <label>PDF URL</label>
                  <input
                    type="text"
                    id="pdfUrl"
                    onChange={handleChange}
                    value={info.pdfUrl}
                    placeholder="Enter PDF URL"
                    className="materialUpdateInput"
                    required
                  />
                </div>
                <button 
                  className="materialUpdateButton" 
                  onClick={handleCreate}
                >
                  Create
                </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateMaterial;
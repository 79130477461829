import React from 'react'
import DataTable from '../../components/dataTable'

const CareerList = () => {
  return (
    <div className='list'>
        <DataTable/>
    </div>
  )
}

export default CareerList
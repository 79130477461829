import { axiosInstance } from "../../config";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../authContext";
import "./login.scss";

const Login = () => {
  const [credentials, setCredentials] = useState({
    phoneNoOrEmail:undefined,
    password:undefined
  });

  const { loading, error, dispatch } = useContext(AuthContext);

  const navigate = useNavigate()

  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });
    try {
      
      const res = await axiosInstance.post("/admin-login", credentials);
      
      // axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${res.data.response.token}`;
      
      if(res.data.response.user.isAdmin){
        dispatch({ type: "LOGIN_SUCCESS", payload: res.data.response.user });
        navigate("/")
      }
      else{
        dispatch({ type: "LOGIN_FAILURE", payload: {message: "You are not admin!"} });
      }
    } catch (err) {
      dispatch({ type: "LOGIN_FAILURE", payload: err.response.data });
    }
  };

  return (
    <div className="login">
      <div className="lContainer">
        <h3>ADMIN LOGIN</h3>
        <input
          type="email"
          placeholder="email"
          id="phoneNoOrEmail"
          onChange={handleChange}
          className="lInput"
          required
        />
        <input
          type="password"
          placeholder="password"
          id="password"
          onChange={handleChange}
          className="lInput"
          required
        />
        { error && <span style={{ color: "red" }} >{error.msg}</span> }
        <button disabled={loading} onClick={handleClick} className="lButton">
          Login
        </button>
      </div>
    </div>
  );
};

export default Login;
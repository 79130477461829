import { createSlice } from '@reduxjs/toolkit';

const intialState = {
    value: null,
}

const dataSlice3 = createSlice({
    name:'data',
    initialState: intialState,
    reducers: {
        active: (state, action) => {
            state.value = action.payload;
        },
        notActive: (state) => {
            state.value = null;
        }
    },
})

export const { active,notActive } = dataSlice3.actions

export default dataSlice3.reducer
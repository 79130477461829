import React, { useState } from 'react'
import "../../components/dataTable/datatable.scss"
import { DataGrid } from '@mui/x-data-grid';
import useFetch from '../../hooks/useFetch'
import { useEffect } from 'react';
import { axiosInstance } from '../../config';
// import { useDispatch } from 'react-redux';
import Spinner from '../../components/spinner';
import "../carouselEntries/carousel.scss"

const columns = [
    {
        field: 'title',
        headerName: 'Announcement Title',
        width: 400,
    },
    {
        field: 'rediectLink',
        headerName: 'Redirect Link',
        width: 400,
        renderCell: (params) => (
            <a href={params.value} target="_blank" rel="noopener noreferrer">
                {params.value}
            </a>
        ),
    }
];

const AnnouncementEntries = () => {

    const [list, setList] = useState();
    const [loading, setLoading] = useState();
    const { data } = useFetch("/announcement-entry/")
    const [info, setInfo] = useState({
        title: '',
        redirectLink: ''
    });
    
    useEffect(() => {
        setList(data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
    },[data])

    const handleDelete = async (id) => {
        try{
            setLoading(true);
            await axiosInstance.delete(`/announcement-entry/${id}`)
            setList(list.filter((item) => item._id !== id))
            setLoading(false);
        }catch(err){
            console.log(err);
        }
    }

    const handleChange = (e) => {
        e.preventDefault();
        setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };
    
    const handleCreate = async () => {
        try{
            await axiosInstance.post(`/announcement-entry/`, info);
            setInfo({
                title: '',
                redirectLink: ''
            });
            alert("Announcement entry created!");
        }catch(err){
            console.log(err);
        }
    }

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 150,
            renderCell: (params)=>{
                return(
                    <div className='cellAction'>
                        <div className='deleteBtn' onClick={() => handleDelete(params.row._id)}>DELETE</div>
                    </div>
                )
            }
        }
    ]
  return (
    <div className='carousel-wrapper'>  
        <div className='create-box'>
            <form className="createForm">
                <h3>Create a New Announcement</h3>
                <div className="formItem">
                  <label>Title <span>(required)</span></label>
                  <input
                    type="textarea"
                    id = "title"
                    onChange={handleChange}
                    placeholder="Enter your Announcement Title"
                    className="formInput"
                    required
                  />
                </div>
                <div className="formItem">
                  <label>Rediect URL <span>(required)</span></label>
                  <input
                    type="textarea"
                    id = "rediectLink"
                    onChange={handleChange}
                    placeholder="https://rightguru.com/something"
                    className="formInput"
                    required
                  />
                </div>
                <button className="formCreateButton" onClick={() => handleCreate()}>Create</button>
            </form>
            <div className='instructions'>
                <p><b>Instructions - </b></p>
                <p>1. Double check the URLs before adding.</p>
                <p>2. Title - It's the text you'll see in live webiste.</p>
                <p>3. Please enter '#' in Redirect URL if you want it to do nothing.</p>
            </div>
        </div>
        <div className='data-table'>
            {
                loading && <Spinner/>
            }
            
            { !loading && list && <DataGrid
                className='dataGrid'
                rows = {list}
                columns={columns.concat(actionColumn)}
                pageSize={8}
                rowsPerPageOptions={[8]}
                getRowId={row => row._id}
            />}
        </div>
    </div>
  )
}

export default AnnouncementEntries

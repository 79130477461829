import React,{ useState } from 'react'
import "../singleCareer/single.scss"
import { Link } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { active } from '../../redux/dataSlice3';
import { axiosInstance } from '../../config';
import Spinner from '../../components/spinner';

const SingleLead = () => {

  const [info, setInfo] = useState();
  const [updated, setUpdated] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.value);

  const handleChange = (e) => {
    setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleUpdate = async (id, e) => {
    e.preventDefault();
    try{
        const updatedFranchise = { ...info }
        const update = await axiosInstance.put(`/lead-form/${id}`, updatedFranchise)
        setInfo({});
        dispatch(active(update.data))
        setUpdated(true);
    }catch(err){
        console.log(err);
    }
  }

  return (
    <div>
      {
        !data && <Spinner/>
      }
    {data && 
    <div className='single'>
        <div className='top'>
          <p>Lead Form Details</p>
          <Link to='/leads'> 
            <button className='Btn'>Back</button>
          </Link>
        </div>
        <div className="client">
          <div className="userShow">
            <div className="userShowTop">
              <div className="userShowTopTitle">
                <span className="userShowFullname">{data.name}</span>
              </div>
            </div>
            <div className="userShowBottom">
              <div className="userShowInfo">
                <span>Status: </span>
                <span className="userShowInfoTitle">{data.status}</span>
              </div>
              <div className="userShowInfo">
                <span>Phone: </span>
                <span className="userShowInfoTitle">{data.phone}</span>
              </div>
              <div className="userShowInfo">
                <span>Email: </span>
                <span className="userShowInfoTitle">{data.email}</span>
              </div>
              <div className="userShowInfo">
                <span>Qualification: </span>
                <span className="userShowInfoTitle">{data.qualification}</span>
              </div>
              <div className="userShowInfo">
                <span>Exam: </span>
                <span className="userShowInfoTitle">{data.exam}</span>
              </div>
              <div className="userShowInfo">
                <span>Address: </span>
                <span className="userShowInfoTitle">{data.address}</span>
              </div>
              <div className="userShowInfo">
                <span>Note: </span>
                <div className="userShowInfoTitle">{data.remarks}</div>
              </div>
            </div>
          </div>
          <div className="userUpdate">
            <span className="userUpdateTitle">EDIT</span>
            {updated && (
                <p className='updated'>
                  <span>Details Updated Successfully!</span>
                  <span onClick={() => setUpdated(false)} style={{ cursor: 'pointer' }} >X</span>
                </p>
            )}
            <form className="userUpdateForm">
              <div className="userUpdateLeft">
                <div className="userUpdateItem">
                  <label>Name</label>
                  <input
                    type="text"
                    id = "name"
                    onChange={handleChange}
                    placeholder={data.name}
                    className="userUpdateInput"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Phone</label>
                  <input
                    type="text"
                    id = "phone"
                    onChange={handleChange}
                    placeholder={data.phone}
                    className="userUpdateInput"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Email</label>
                  <input
                    type="text"
                    id = "email"
                    onChange={handleChange}
                    placeholder={data.email}
                    className="userUpdateInput"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Qualification:</label>
                  <input
                    type="text"
                    id = "qualification"
                    onChange={handleChange}
                    placeholder={data.qualification}
                    className="userUpdateInput"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Target Exam:</label>
                  <input
                    type="text"
                    id = "exam"
                    onChange={handleChange}
                    placeholder={data.exam}
                    className="userUpdateInput"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Address:</label>
                  <input
                    type="text"
                    id = "address"
                    onChange={handleChange}
                    placeholder={data.address}
                    className="userUpdateInput"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Note:</label>
                  <input
                    type="text"
                    id = "remarks"
                    onChange={handleChange}
                    placeholder={data.remarks}
                    className="userUpdateInput"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Status</label>
                  <select name="status" onChange={handleChange} id="status">
                    <option value={data.status}>Select</option>
                    <option value="New">New</option>
                    <option value="In-process">In-process</option>
                    <option value="Discarded">Discarded</option>
                  </select>
                </div>
                <button className="userUpdateButton" onClick={(e) => handleUpdate(data._id, e)}>Update</button>
              </div>
            </form>
          </div>
        </div>
    </div>
    }
    </div>
  )
}

export default SingleLead